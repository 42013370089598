<template>
  <div class="index">
      <!-- follow me on twitter @asad_codes -->
      <div class="relative flex items-top justify-center min-h-screen bg-gray-100 sm:items-center sm:pt-0">
    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.1.2/dist/tailwind.min.css" rel="stylesheet">
    <div class="min-w-10xl mx-auto sm:px-6 lg:px-8 w-10/12">
      <div class="mt-8 bg-white overflow-hidden shadow sm:rounded-lg p-6">
        <div class="bg-white w-full p-4">
                <div class="mb-5">
            <button class="float-right bg-blue-500 hover:bg-blue-400 text-white font-semibold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
                Login
            </button>
        </div>
                <p class="float left text-black-100 text-3xl font-bold">
                    CHEAT.SCHOOL
                </p>
                <br>
                <ul class="flex border-b">
                     <li class="m-1" v-for="(question, index) in question_instance_array" :key="index" >
                         <a v-if="question.active" class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold" href="#">Active</a>
                         <a @click="change_tab(index)" v-else class="bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold" href="#">{{ question.question }}</a>
                     </li>
                    <!-- <li class="-mb-px mr-1">
                        <a class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold" href="#">--__--</a>
                    </li>
                    <li class="mr-1" v-for="question in question_instance_array" :key="question.id" >

                    
                    <li
                        is="question_instance_array"
                        v-for="(question, index) in question_instance_array"
                        v-bind:title="question_instance_array.title"
                        v-on:remove="question_instance_array.splice(index, 1)"
                        @click="active_question = index"
                        @click="active_question = question.id"
                    > -->
                    <!-- {{ index + }} -->
                    <!-- {{ active_question }}
                    {{ question.id }} -->
                    <!-- {{ active_question = question.id }}
                    {{ question.id != active_question }} -->

                    <!-- <a  class="bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold" href="#">{{ question.question }}</a> -->
                    <!-- <a v-else class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold" href="#">Active</a> -->
                    <!-- {{ question }} -->
                    <!-- </li>-->

                    <!-- <li class="-mb-px mr-1">
                        <a class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold" href="#">Active</a>
                    </li>
                    <li class="mr-1">
                        <a class="bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold" href="#">Tab</a>
                    </li>
                    <li class="mr-1">
                        <a class="bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold" href="#">Tab</a>
                    </li>
                    <li class="mr-1">
                        <a class="bg-white inline-block py-2 px-4 text-gray-400 font-semibold" href="#">+</a>
                    </li> -->
                    <li class="mr-1">
                        <a class="bg-white inline-block py-2 px-4 text-gray-400 font-semibold" href="#">+</a>
                    </li>
                </ul>
                <!-- <p class="text-gray-600 font-light text-md">
                    It is difficult to believe that we have become so used to having instant access to information at...
                    <a class="inline-flex text-indigo-500" href="#">Read More</a>
                </p> -->
                <div class="flex flex-col space-y-5">
                    <label for="default" class="text-gray-700 select-none font-medium">Type your question here</label>
                    <!-- <input
                    v-model="gpt_input"
                    id="default"
                    type="text"
                    name="Type your question here"
                    placeholder="Ask Away"
                    class="resize px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200"
                    /> -->
                    <textarea v-model="gpt_input" class="resize border rounded-md"></textarea>
                </div>
                
                <!-- <button  @click="requestGPT()" class="p-2 pl-5 pr-5 bg-transparent border-2 border-indigo-500 text-indigo-500 text-lg rounded-lg transition-colors duration-700 transform hover:bg-indigo-500 hover:text-gray-100 focus:border-4 focus:border-indigo-300">Ask</button> -->
                <!-- <div v-if=""> -->
                <!-- <p>This is the current user {{ current_user }}</p> -->
                <br>
                <div  @click="requestGPT()" class="float-left px-4 py-2 rounded-md text-sm font-medium border-0 focus:outline-none focus:ring transition text-white bg-gray-500 hover:bg-gray-600 active:bg-gray-700 focus:ring-gray-300">Ask</div>
                <button disabled class="px-4 py-2 rounded-md cursor-default text-sm font-medium focus:outline-none focus:ring transition text-white bg-gray-300 focus:ring-gray-200" type="submit">Sign in First</button>
                <!-- <div class="flex flex-wrap justify-starts items-center py-3 border-b-2 text-xs text-white font-medium">
                    <span class="m-1 px-2 py-1 rounded bg-indigo-500">
                        #online
                    </span>
                    <span class="m-1 px-2 py-1 rounded bg-indigo-500">
                        #internet
                    </span>
                    <span class="m-1 px-2 py-1 rounded bg-indigo-500">
                        #education
                    </span>
                </div> -->
                <p class="mt-4 pt-4 text-gray-800 border-t border-dashed"></p>
                <div v-if="gpt_response">
                    {{ gpt_response }}
                </div>
                <div class="flex float-right items-center mt-2">
                    <img class='w-10 h-10 object-cover rounded-full' alt='User avatar' :src="photoURL">
        
                    <div class="pl-3">
                        <div class="font-medium">
                            {{ displayname }}
                        </div>
                        <div class="text-gray-600 text-sm">
                            Email of signed in user
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  </div>
    <div class="mdl-grid">
    <div class="mdl-cell mdl-cell--4-col"></div>
    <div class="mdl-cell mdl-cell--4-col">
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
        <input class="mdl-textfield__input" type="email" id="txtEmail" v-model="authInput.txtEmail">
        <label class="mdl-textfield__label" for="txtEmail">Email</label>
        </div>
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
        <input class="mdl-textfield__input" type="Password" id="txtPassword" v-model="authInput.txtPassword">
        <label class="mdl-textfield__label" for="txtPassword">Password</label>
        </div>
        <button id="btnLogin" v-on:click="Login()" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--primary">
    Log in
    </button>
    <button id="btnSignUp" v-on:click="SignUp()"  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
    Sign Up
    </button>
    <button id="btnLogout" v-on:click="LogOut()" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button" >
    Log out
    </button>
    <button v-on:click="googleLogin()" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button">
    Google in
    </button>
    </div>
    <div class="mdl-cell mdl-cell--4-col"></div>
     <div id="database">
    <!-- <img src="./assets/logo.png" height="150px"> -->
    <!-- Adding a new greeting; pretty much straight from the documentation! -->
    <form id="form" v-on:submit.prevent="addGreeting">
      <input type="text" v-model="newGreeting.lang" placeholder="Language Name">
      <input type="text" v-model="newGreeting.text" placeholder="How do you say hello?">
      <input type="submit" value="Add Greeting">
    </form>
    <!--
      Here we are iterating through a very simply array of greetings in
      different languages. To do this, we use the "v-for" directive. This
      is linked to our demo firebase instance, which is described below.
    -->
            <h1 v-for="greeting in greetings" :key="greeting.id">{{ greeting.text }}</h1>
        </div>
    </div>
  </div>

  
</template>

<script>
import firebase from 'firebase'
    // const config = {
    //     apiKey: "AIzaSyDtov_JpYIfpH0IfA9ym-FeES1WWU37JX4",
    //     authDomain: "studywithai.firebaseapp.com",
    //     databaseURL: "https://studywithai-default-rtdb.firebaseio.com/",
    //     projectId: "studywithai",
    //     storageBucket: "studywithai.appspot.com",
    //     messagingSenderId: "1064507156436",
    //     appId: "1:1064507156436:web:cf2228eed70cb0c0b8d841",
    //     measurementId: "G-QX8MWDL1FB"
    // }
     const app = firebase.app();
    var provider = new firebase.auth.GoogleAuthProvider();

    // const config = {
    //     apiKey: "AIzaSyDtov_JpYIfpH0IfA9ym-FeES1WWU37JX4",
    //     authDomain: "studywithai.firebase.com",
    //     databaseURL: "https://studywithai-default-rtdb.firebaseio.com/",
    //     projectId: "studywithai",
    //     storageBucket: "studywithai.appspot.com",
    //     messagingSenderId: "1064507156436",
    //     appId: "1:1064507156436:web:cf2228eed70cb0c0b8d841",
    //     measurementId: "G-QX8MWDL1FB"
    // }

    // Here we are initializing the firebase connection.
    //let app = firebase.initializeApp(config)
    let db = app.database()

    // Accessing the greetings reference; .ref() takes a URL as its parameter.
    let greetingsRef = db.ref('greetings')


    export default {
        name: "index",
        firebase: {
            greetings: greetingsRef.limitToLast(5)
        },
        data() {
            return {
                displayname: 'Display Name',
                // current_user: null,
                jwt: "",
                gpt_response: null,
                google_login_result: '',
                gpt_input: "",
                photoURL: '',
                authInput: {
                    txtEmail: '',
                    txtPassword: ''
                },
                newGreeting: {
                    lang: '',
                    text: ''
                },
                active_question: 0,
                question_instance_array: [
                {
                    active: false,
                    question: 'q1',
                },
                {
                    active: true,
                    question: 'q2',
                },
                {
                    active: false,
                    question: 'q3'
                }
                ],
            }
        },
        // watcher
        beforeMount() {
            //While firebase is initializing `firebase.auth().currentUser` will be null
            //console.log("yeet")
            let currentUser = firebase.auth().currentUser;

            //console.log(currentUser)

            //Check currentUser in case you previously initialize firebase somewhere else
            if (currentUser) {
            //if currentUser is ready we just finish here
            //console.log(currentUser)
            this.displayname = currentUser.displayname;
            this.photoURL = currentUser.photoURL;
            // this.authorization = currentUser.credential;
            // console.log
            } else {
            // if currentUser isn't ready we need to listen for changes
            // onAuthStateChanged takes a functions as callback and also return a function
            // to stop listening for changes 
            let authListenerUnsuscribe = firebase.auth().onAuthStateChanged(user => {
                //onAuthStateChanged can pass null when logout 
                if (user) {
                //console.log(user.credential.idToken)
                //console.log(user.email)
                this.photoURL = user.photoURL;
                this.displayname = user.email;
                //this.id_token = user.credential.idToken;
                authListenerUnsuscribe(); //Stop listening for changes
                }
            });
            }
        },
        computed: {
            current_user: function () {
                return firebase.auth().currentUser
            }
        },
        methods: {
            async requestGPT(){
                console.log(firebase.auth().currentUser.getToken().ra)
                const requestOptions = {
                    method: "POST",
                    headers: { 
                        "Content-Type": "application/json",
                        "jwt" : await firebase.auth().currentUser.getToken(), // firebase.auth().currentUser.getToken().currentUser,
                        // 'Access-Control-Allow-Origin': "*"
                     },
                    body: JSON.stringify({ question: this.gpt_input})
                };
                const response = await fetch("/search", requestOptions);
                const data = await response.json();
                this.gpt_response = data;
            },
            change_tab: function(index){
                this.question_instance_array.forEach(tab => {
                    tab.active = false
                });
                //console.log(index)
                this.question_instance_array[index].active = !this.question_instance_array[index].active
                // this.gpt_input = tab.question
                // for (let i = 0; i < this.question_instance_array.length; i++) {
                //     console.log(i + 'first1')
                //     if(i = index){
                //         this.question_instance_array[i].active = true
                //     }else{
                //         this.question_instance_array[i].active = false
                //     }
                //    //const element = this.question_instance_array[i];
                    
                // }
            },
            addGreeting: function () {
                greetingsRef.push(this.newGreeting)
                this.newGreeting.lang = ''
                this.newGreeting.text = ''
            },
            Login: function(event) {
                console.log(event)
                const email = this.authInput.txtEmail;
                const pass = this.authInput.txtPassword;
                const auth = firebase.auth();
                // const recaptcha = firebase.auth().RecaptchaVerifier
                const promise = auth.signInWithEmailAndPassword(email, pass);
                this.authInput.txtEmail = '';
                this.authInput.txtPassword = '';
                promise.catch(event => console.log(event.message));
            },
            SignUp: function(event) {
                console.log(event);
                const email = this.authInput.txtEmail;
                const pass = this.authInput.txtPassword;
                const auth = firebase.auth();
                const promise = auth.createUserWithEmailAndPassword(email, pass);
                this.authInput.txtEmail = '';
                this.authInput.txtPassword = '';
                promise.catch(event => console.log(event.message));

            },
            googleLogin: function() {
                var that = this;
                firebase.auth().signInWithPopup(provider).then(function(result) {
                    // // console.log(result);
                    // console.log(result.credential.idToken)
                    that.jwt = result.credential.idToken
                    //that.jwt = firebase.auth().currentUser.getToken()
                    console.log(firebase.auth().currentUser.getToken())
                    // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    //     that.jwt = idToken
                    // }).catch(function(error) {
                    //     console.log(error)
                    // });
                    return result
                }).catch(function(error) {
                    console.log("An error with google sign in has occured: " + error);
                });
            },
            LogOut: function() {
                firebase.auth().signOut();
            },
            // async store_firebase_user(user){
            //     this.current_user = user
            // }
        }

    }
    // this.firebaseUser = firebase.auth().
    // firebase.auth().onAuthStateChanged(firebaseUser => {
    //     // console.log("This is the displayname: " + firebaseUser.displayname);
    //     //console.log(firebase.auth().currentUser.displayName())
    //     //this.displayname = firebase.auth().currentUser.displayName
    //     if (firebaseUser) {
    //         // firebaseUser.sendEmailVerification().then(function() {
    //         //     console.log('send Verification');
    //         // }, function(error) {
    //         //     console.log('not send Verification');
    //         // });
    //         if (firebaseUser.emailVerified == true) {
    //             //console.log('login success');
    //             //document.getElementById('btnLogout').style.display = '';
    //             //this.store_firebase_user(firebaseUser)
    //         } else {
    //             //document.getElementById('btnLogout').style.display = 'none';
    //         }

    //     } else {
    //         console.log('not loggend in');
    //         //document.getElementById('btnLogout').style.display = 'none';
    //     }
    // })
</script>
