import Vue from 'vue'
import Router from 'vue-router'
//import index from '@/components/index'
// import database from '@/components/database'

//layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts


import main_dashboard from "@/views/main_dashboard.vue";
import Payment from "@/views/Payment.vue";
import Landing from "@/views/Landing.vue";
import aboutus from "@/views/aboutus.vue";
import contact from "@/views/contact.vue";
import expertise from "@/views/expertise.vue";
//import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";
import windex from "@/views/windex.vue";

//routes

//comp page

// import brain from "../components/brain.vue";

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [{
        path: "/admin",
        redirect: "/admin/dashboard",
        component: Admin,
        children: [
          {
            path: "/admin/dashboard",
            component: Dashboard,
          },
          {
            path: "/admin/settings",
            component: Settings,
          },
          {
            path: "/admin/tables",
            component: Tables,
          },
          {
            path: "/admin/maps",
            component: Maps,
          },
        ],
      },
      {
        path: "/auth",
        redirect: "/auth/login",
        component: Auth,
        children: [
          {
            path: "/auth/login",
            component: Login,
          },
          {
            path: "/auth/register",
            component: Register,
          },
        ],
      },
      {
        path: "/landing",
        component: Landing,
      },
      {
        path: "/about-us",
        component: aboutus,
      },
      {
        path: "/expertise",
        component: expertise,
      },
      {
        path: "/contact",
        component: contact,
      },
      {
        path: "/payment",
        component: Payment,
      },
      {
        path:"/dashboard",
        component: main_dashboard,
      },
      {
        path: "/windex",
        component: windex,
      },
      {
        path: "/",
        component: Index,
      },
      {
        path: '*',
        name: 'catchAll',
        component: Index
     }
      // { path: "/:pathMatch(.*)*", redirect: "/" },
    ]
})
