<template>
  <nav
    class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link to="/">
          <img :src=this.logo_black alt="" class="inline-block" style="width:3em; height:3em; margin-right: 1em;">
          <a
            class="text-black text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            href="#"
          >
            VMD global source
          </a>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <!-- <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <li class="flex items-center">
            <a
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              
            >
              <i
                class="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2"
              />
              How it works
            </a>
          </li>
        </ul> -->
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <!-- <index-dropdown /> -->
          </li>
          <!-- <li class="flex items-center">
            <a
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdemos.creative-tim.com%2Fvue-notus%2F%23%2F"
              target="_blank"
            >
              <i class="text-blueGray-400 fab fa-facebook text-lg leading-lg" />
              <span class="lg:hidden inline-block ml-2">Share</span>
            </a>
          </li>

          <li class="flex items-center">
            <a
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdemos.creative-tim.com%2Fvue-notus%2F%23%2F&text=Start%20your%20development%20with%20a%20Free%20Tailwind%20CSS%20and%20VueJS%20UI%20Kit%20and%20Admin.%20Let%20Vue%20Notus%20amaze%20you%20with%20its%20cool%20features%20and%20build%20tools%20and%20get%20your%20project%20to%20a%20whole%20new%20level.%20"
              target="_blank"
            >
              <i class="text-blueGray-400 fab fa-twitter text-lg leading-lg" />
              <span class="lg:hidden inline-block ml-2">Tweet</span>
            </a>
          </li>

          <li class="flex items-center">
            <a
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              href="https://github.com/creativetimofficial/vue-notus?ref=vn-index-navbar"
              target="_blank"
            >
              <i class="text-blueGray-400 fab fa-github text-lg leading-lg" />
              <span class="lg:hidden inline-block ml-2">Star</span>
            </a>
          </li> -->

          <li class="nav-item">
          <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75" >
            <router-link
                to="/"
              >
                <span class="ml-2">Home</span>
              </router-link>     
          </a>
        </li>
          <li class="nav-item">
          <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75" >
            <router-link
                to="/expertise"
              >
                <span class="ml-2">Expertise</span>
              </router-link>     
          </a>
        </li>
        <li class="nav-item">
          <a class="px-3 py-2 flex items-center text-xs uppercase font-bold  text-black hover:opacity-75 no-over">
            <router-link
                to="/about-us"
              >
                <span class="ml-2">About us</span>
              </router-link>  
          </a>
        </li>
        <li class="nav-item">
          <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75">
            <router-link
                to="/contact"
              >
                <span class="ml-2">Contact</span>
              </router-link>  
          </a>
        </li>

          <li class="">
            <!-- <button
              class="bg-blueGray-600 text-white active:bg-emerald-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button"
            > -->
            
              
              <!-- <button
                v-on:click="googleLogin()"
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-2" :src="google" />
                Google
              </button> -->
            <!-- </button> -->

        <li v-if="!current_user" class="relative flex w-full flex-wrap items-stretch">
          <button
              class="bg-color1 text-white active:bg-emerald-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button"
            >
            
              <i class="fas fa-angle-double-right"></i>
              <router-link
                to="/auth/login"
                class="font-normal w-full whitespace-nowrap bg-transparent"
              >
                Client Portal
              </router-link>
            </button>
          <!-- <span
            class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
          >
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            placeholder="Search here..."
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          /> -->
        </li>
        <li v-else>
          <button
              class="bg-color1 text-white active:bg-color1 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button"
              style="margin-right:1em;"
            >
            
              <i class="fas fa-angle-double-right"></i>
              <router-link
                to="/dashboard"
                class="font-normal w-full whitespace-nowrap bg-transparent"
              >
                Client Portal
              </router-link>
            </button>
        </li>
      <!-- User -->
      <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
        <user-dropdown />
      </ul>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import google from "@/assets/img/google.svg";
// import IndexDropdown from "@/components/Dropdowns/IndexDropdown.vue";
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import logo_black from "@/assets/img/vmd_logo_gear_black.png";

import firebase from 'firebase'

var provider = new firebase.auth.GoogleAuthProvider();

export default {
  data() {
    return {
      logo_black,
      navbarOpen: false,
      google,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
    googleLogin: function() {
                var that = this
                firebase.auth().signInWithPopup(provider).then(function(result) {
                  //console.log("This is the user that is returned on login");
                  console.log(result.user);
                  //that.set_user_rtdb(result);
                  //console.log(result.credential.idToken)
                  //this.google_login_result = result
                  //console.log("This is where the router should push to console");
              
                  that.push_router_to_console()
                }).catch(function() {
                    // console.log("An error with google sign in has occured: " + error);
                    that.push_router_to_console()
                });
            },
    push_router_to_console: function(){
                this.$router.push('/admin/settings')
            },
  },
  components: {
    // IndexDropdown,
    UserDropdown,
  },
  computed: {
            current_user: function () {
                return firebase.auth().currentUser
            }
  },
};
</script>

<style scoped>
.no-over{
  white-space: nowrap;
}
  
</style>