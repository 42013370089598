<template>
  <div>
    <!-- <index-navbar /> -->
    <!-- <brain></brain> -->
    
    <div class="top_buffer w-full lg:w-12/12">
      <Landing />
    </div>
  </div>
</template>
<script>
// import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
// import FooterComponent from "@/components/Footers/Footer.vue";

// import brain from "@/components/brain.vue";

// import CardSettings from "@/components/Cards/CardSettings.vue";
import Landing from "@/views/Landing.vue";
// import lineChart from "@/components/Cards/CardLineChart.vue";
// import landing_statues from "@/components/landing_statues.vue";
// import CardLineChart from "@/components/Cards/CardLineChart.vue";

import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
// import componentInfo2 from "@/assets/img/component-info-2.png";
// import componentMenu from "@/assets/img/component-menu.png";
// import componentBtnPink from "@/assets/img/component-btn-pink.png";
// import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";
import brain_round from "@/assets/img/brain_round.svg";
import fixed_square_hold_brain from "@/assets/img/fixed_square_hold_brain.svg";

export default {
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      // componentInfo2,
      // componentMenu,
      // componentBtnPink,
      // documentation,
      login,
      profile,
      landing,
      brain_round,
      fixed_square_hold_brain,
      landing_name: "VMD_GLOBAL",
    };
  },
  components: {
    // landing_statues,
    // IndexNavbar,
    Landing,
    // lineChart,
    // CardSettings,
    // FooterComponent,
    // brain,
  },
};
</script>
<style>
/* .top_buffer{
  padding-top: 5em;
} */
.cursor {
  border-right: .1em solid;
  color: #64748b;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}
</style>
