<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-500-px" style="height: 380px;">
        <!-- <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div> -->
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover "
          style="
            background-image: url('/img/stock_floating_gears.e9687c64.jpeg');
          "
        >
          <!-- <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span> -->
        </div>
        <!-- <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
        </div> -->
      </section>
      <section class="relative py-16 bg-white">
        <div class="container mx-auto px-4">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="logo_black"
                      class="shadow-xl bg-white rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </div>
                </div>
                <div
                  class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
                >
                  <div class="py-6 px-3 mt-32 sm:mt-0">
                    <router-link to="/about-us"
                     >
                    <button
                      class="bg-color1 active:bg-color2 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      
                      
                
                        About Us
                
                    </button>
                    </router-link>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 lg:order-1">
                  <div class="flex justify-center py-4 lg:pt-4 pt-8">
                    <div class="mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        
                      </span>
                      <span class="text-sm text-blueGray-400"></span>
                    </div>
                    <div class="mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                         VMD Global Source
                      </span>
                      <span class="text-sm text-blueGray-400">Global Supply Chain</span>
                    </div>
                    <div class="lg:mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        
                      </span>
                      <span class="text-sm text-blueGray-400"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="text-center mt-12">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
                >
                  Jenna Stones
                </h3>
                <div
                  class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  Los Angeles, California
                </div>
                <div class="mb-2 text-blueGray-600 mt-10">
                  <i
                    class="fas fa-briefcase mr-2 text-lg text-blueGray-400"
                  ></i>
                  Solution Manager - Creative Tim Officer
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  University of Computer Science
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      An artist of considerable range, Jenna the name taken by
                      Melbourne-raised, Brooklyn-based Nick Murphy writes,
                      performs and records all of his own music, giving it a
                      warm, intimate feel with a solid groove structure. An
                      artist of considerable range.
                    </p>
                    <a
                      href="javascript:void(0)"
                      class="font-normal text-emerald-500"
                    >
                      Show more
                    </a>
                  </div>
                </div>
              </div> -->
              <div class="container px-4 mx-auto">
                <div class="flex flex-wrap justify-center">
                    <div class="w-full px-4 flex-1">
                        <h2 class="text-5xl font-normal leading-normal mt-0 mb-2 text-emerald-800">
                        Contact US
                        </h2>
                    <span class="text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
                        <!-- <p>
                        Tel: <br><a class="text-xl" href="tel:+1 (603) 534-0550">+1 (603) 534-0550</a>
                        </p>
                        <br>
                        <p>
                        Email: <br><a class="text-xl" href="mailto:ktownson@vmdglobalsource.com">ktownson@vmdglobalsource.com</a>
                        <br><a class="text-xl" href="mailto:dfritz@vmdglobalsource.com">dfritz@vmdglobalsource.com</a>
                        </p>
                        <br>
                        <p>
                        Kyle Townson, Managing Partner
                        </p>
                        <p>
                        M: +1 (603) 534-0550
                        </p>
                        <p>
                        E: ktownson@vmdglobalsource.com 
                        </p>
                        <br>
                        <p>
                        Dan Fritz, Managing Partner
                        </p>
                        <p>
                        M: +1 (603) 997-9859
                        </p>
                        <p>
                        E: dfritz@vmdglobalsource.com 
                        </p> -->
                        </span>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">
                    Want to work with us?
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <div class="relative w-full mb-3 mt-8">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Full Name
                    </label>
                    <input
                      v-model="full_name_form"
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Full Name"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      v-model="email_form"
                      type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      v-model="message_form"
                      rows="4"
                      cols="80"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Type a message..."
                    />
                  </div>
                  <div class="text-center mt-6">
                    <button
                      @click="send_form_info()"
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
                </div>
            </div>  
            </div>
          </div>
        </div>
      </section>

    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/FooterAdmin.vue";

import team2 from "@/assets/img/team-2-800x800.jpg";
import logo_black from "@/assets/img/vmd_logo_gear_color.png";


import axios from "axios";

export default {
  data() {
    return {
      team2,
      logo_black,
      full_name_form: '',
      email_form: '',
      message_form: '',
      post_response: '',
      errorMessage: '',
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
  methods:{
    send_form_info(){
      const form_info = { 
        full_name: this.full_name_form,
        email: this.email_form,
        message: this.message_form };
      axios.post("https://us-central1-vmdglobalsourcing.cloudfunctions.net/sendMailOverHTTP", form_info)
        .then(response => this.post_response = response)
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
        this.full_name_form = '';
        this.email_form = '';
        this.message_form = '';
        this.$swal({
            icon: 'success',
            title: 'Form has been sent!',
            html: 'Please give us 24 hours to respond to your message!'
            });
    },
  },
};
</script>
