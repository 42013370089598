<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import firebase from 'firebase'
const firebaseConfig = {

  apiKey: "AIzaSyCpEe-70rwjrTVaYXI6A1V7VxS0K_3T_eo",

  authDomain: "vmdglobalsourcing.firebaseapp.com",

  databaseURL: "https://vmdglobalsourcing-default-rtdb.firebaseio.com",

  projectId: "vmdglobalsourcing",

  storageBucket: "vmdglobalsourcing.appspot.com",

  messagingSenderId: "964533306968",

  appId: "1:964533306968:web:07420ed4bd2642769c2c2d",

  measurementId: "G-WHFQVFYK79"

};

    //const config = firebase.remoteConfig();
    //const app = firebase.initializeApp(config);
   firebase.initializeApp(firebaseConfig);
  
    // var provider = new firebase.auth.GoogleAuthProvider();
export default {
  name: 'app'
  //provider
}
</script>

<style>
</style>