<template>
  <div>
    <navbar />
    <main class="profile-page">
        <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center "
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover "
          style="
            background-image: url('/img/stock_floating_gears.e9687c64.jpeg');
          "
        >
          <!-- <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span> -->
        </div>

        

        <!-- <section class="mt-20 relative block bg-white rounded shadow-lg">

        <div class="container mx-auto px-6 lg:pt-24 lg:pb-64 ">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-black">
                Expertise
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color1 uppercase last:mr-0 mr-1">
                  Air Freight
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color2 uppercase last:mr-0 mr-1">
                  Ocean Freight
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color3 uppercase last:mr-0 mr-1">
                  Trucking
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color4 uppercase last:mr-0 mr-1">
                  Rail
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color1 uppercase last:mr-0 mr-1">
                  Intermodal Services
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color4 uppercase last:mr-0 mr-1">
                  Documentation Management
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color3 uppercase last:mr-0 mr-1">
                  Customs & FDA Clearance
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color2 uppercase last:mr-0 mr-1">
                  Distribution
                </span>
              </p>
            </div>
          </div>
          <div class="flex flex-wrap pt-20">
            <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(1)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 1, 'text-white bg-color3': openTab === 1}">
                    <i class="fas fa-medal text-base mr-1"></i> LOGISTICS
                </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(2)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 2, 'text-white bg-color3': openTab === 2}">
                    <i class="fas fa-poll text-base mr-1"></i> SUPPLY CHAIN OPTIMIZATION 
                </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(3)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 3, 'text-white bg-color3': openTab === 3}">
                    <i class="fas fa-lightbulb text-base mr-1"></i> SUSTAINABLE MATERIALS 
                </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(4)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 4, 'text-white bg-color3': openTab === 4}">
                    <i class="fas fa-cog text-base mr-1"></i> COMMODITIES AND BULK GOODS 
                </a>
                </li>
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div class="px-4 py-5 flex-auto">
                <div class="tab-content tab-space">
                    <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
                    
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-black">
                LOGISTICS
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Not having goods delivered on-time can have serious consequences and navigating a complex
international trade and global logistics system can be a daunting. Allow our experienced team to ease
the burden. Our logistics team operates with urgency, ownership, and respect for the importance of
every delivery under our watch. We use a process and technology driven approach to ensure your goods
arrive efficiently and on time, every time.
              </p>
                    </div>
                    <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUPPLY CHAIN OPTIMIZATION
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Every supply chain has its challenges and pain points… today this more evident than ever. Our team
flourishes in these challenges. Our expertise and network of global manufacturing partners allow us to
view supply chain challenges through a unique lens constantly adjusting from a global perspective to a
focused detail. Equipped with this perspective, we develop turnkey solutions to create cost savings,
improve efficiency, and better quality control.
              </p>
                    </div>
                    <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUSTAINABLE MATERIALS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                The efficient use of sustainable materials can provide too many benefits to list, but too often companies
are faced with the question to do what’s best for the planet or what’s best for their bottom line, and the
benefits are unable to out-weigh the costs. We strive to tip the scales by implementing sustainable
materials and environmentally friendly manufacturing practices all while creating cost-savings.
              </p>
                    </div>
                    <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                COMMODITIES AND BULK GOODS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                
                Our focus on optimization and efficiency paired with the relationships our team has built across the
globe have positioned us to be competitive even in the most competitive of markets.
              </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      </section> -->

      <!-- <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-black">
                LOGISTICS
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Not having goods delivered on-time can have serious consequences and navigating a complex
international trade and global logistics system can be a daunting. Allow our experienced team to ease
the burden. Our logistics team operates with urgency, ownership, and respect for the importance of
every delivery under our watch. We use a process and technology driven approach to ensure your goods
arrive efficiently and on time, every time.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUPPLY CHAIN OPTIMIZATION
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Every supply chain has its challenges and pain points… today this more evident than ever. Our team
flourishes in these challenges. Our expertise and network of global manufacturing partners allow us to
view supply chain challenges through a unique lens constantly adjusting from a global perspective to a
focused detail. Equipped with this perspective, we develop turnkey solutions to create cost savings,
improve efficiency, and better quality control.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUSTAINABLE MATERIALS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                The efficient use of sustainable materials can provide too many benefits to list, but too often companies
are faced with the question to do what’s best for the planet or what’s best for their bottom line, and the
benefits are unable to out-weigh the costs. We strive to tip the scales by implementing sustainable
materials and environmentally friendly manufacturing practices all while creating cost-savings.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                COMMODITIES AND BULK GOODS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Our focus on optimization and efficiency paired with the relationships our team has built across the
globe have positioned us to be competitive even in the most competitive of markets.
              </p>
            </div>
          </div> -->

      <section class="relative py-10 mt-20 relative block bg-white rounded shadow-lg">
        <div class="container mx-auto px-4 pb-32 pt-48">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <!-- <i class="fas fa-cog text-xl"></i> -->
                <i class="fas"><img :src="single_gear_light" class="" alt=""/></i>
              </div>
              <!-- <h3 class="text-3xl font-semibold">
                Complex Documentation
              </h3> -->
              <div class="w-full lg:w-10/12 px-4">
              <h2 class="text-4xl font-semibold text-black">
                Expertise
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color1 uppercase last:mr-0 mr-1">
                  Air Freight
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color2 uppercase last:mr-0 mr-1">
                  Ocean Freight
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color3 uppercase last:mr-0 mr-1">
                  Trucking
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color4 uppercase last:mr-0 mr-1">
                  Rail
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color1 uppercase last:mr-0 mr-1">
                  Intermodal Services
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color4 uppercase last:mr-0 mr-1">
                  Documentation Management
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color3 uppercase last:mr-0 mr-1">
                  Customs & FDA Clearance
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color2 uppercase last:mr-0 mr-1">
                  Distribution
                </span>
              </p>
            </div>
              <!-- <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                This extension comes a lot of fully coded examples that help you
                get started faster. You can adjust the colors and also the
                programming language. You can change the text and images and
                you're good to go.
              </p> -->
              <!-- <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-fingerprint"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Built by Developers for Developers
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fab fa-html5"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Carefully crafted code for Components
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="far fa-paper-plane"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Dynamic Javascript Components
                      </h4>
                    </div>
                  </div>
                </li>
              </ul> -->
            </div>


            
          </div>

          <div class="w-full md:w-4/12 mr-auto px-4 pt-24 md:pt-0">
            <img
              alt="..."
              class="max-w-full rounded-lg shadow-xl"
              style="
                transform: scale(1) perspective(1040px) rotateY(-11deg)
                  rotateX(2deg) rotate(2deg);
              "
              :src="stock_floating_gears"
            />
          </div>
        </div>
        
        <div class="flex flex-wrap pt-20 p-8">
            <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(1)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 1, 'text-white bg-color3': openTab === 1}">
                    <i class="fas fa-cog text-base mr-1"></i> LOGISTICS
                </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(2)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 2, 'text-white bg-color3': openTab === 2}">
                    <i class="fas fa-cog text-base mr-1"></i> SUPPLY CHAIN OPTIMIZATION 
                </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(3)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 3, 'text-white bg-color3': openTab === 3}">
                    <i class="fas fa-cog text-base mr-1"></i> SUSTAINABLE MATERIALS 
                </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " v-on:click="toggleTabs(4)" v-bind:class="{'text-blueGray-800 bg-white': openTab !== 4, 'text-white bg-color3': openTab === 4}">
                    <i class="fas fa-cog text-base mr-1"></i> COMMODITIES AND BULK GOODS 
                </a>
                </li>
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div class="px-4 py-5 flex-auto">
                <div class="tab-content tab-space">
                    <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
                    
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-black">
                LOGISTICS
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Not having goods delivered on-time can have serious consequences and navigating a complex
international trade and global logistics system can be a daunting. Allow our experienced team to ease
the burden. Our logistics team operates with urgency, ownership, and respect for the importance of
every delivery under our watch. We use a process and technology driven approach to ensure your goods
arrive efficiently and on time, every time.
              </p>
                    </div>
                    <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUPPLY CHAIN OPTIMIZATION
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Every supply chain has its challenges and pain points… today this more evident than ever. Our team
flourishes in these challenges. Our expertise and network of global manufacturing partners allow us to
view supply chain challenges through a unique lens constantly adjusting from a global perspective to a
focused detail. Equipped with this perspective, we develop turnkey solutions to create cost savings,
improve efficiency, and better quality control.
              </p>
                    </div>
                    <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUSTAINABLE MATERIALS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                The efficient use of sustainable materials can provide too many benefits to list, but too often companies
are faced with the question to do what’s best for the planet or what’s best for their bottom line, and the
benefits are unable to out-weigh the costs. We strive to tip the scales by implementing sustainable
materials and environmentally friendly manufacturing practices all while creating cost-savings.
              </p>
                    </div>
                    <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                COMMODITIES AND BULK GOODS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                
                Our focus on optimization and efficiency paired with the relationships our team has built across the
globe have positioned us to be competitive even in the most competitive of markets.

              </p>
              <br>
            </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <!-- <div class="flex flex-wrap mt-1 justify-center">
            <div class="w-full lg:w-12/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-black">
                LOGISTICS
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Not having goods delivered on-time can have serious consequences and navigating a complex
international trade and global logistics system can be a daunting. Allow our experienced team to ease
the burden. Our logistics team operates with urgency, ownership, and respect for the importance of
every delivery under our watch. We use a process and technology driven approach to ensure your goods
arrive efficiently and on time, every time.
              </p>
            </div>
            <div class="w-full lg:w-12/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUPPLY CHAIN OPTIMIZATION
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Every supply chain has its challenges and pain points… today this more evident than ever. Our team
flourishes in these challenges. Our expertise and network of global manufacturing partners allow us to
view supply chain challenges through a unique lens constantly adjusting from a global perspective to a
focused detail. Equipped with this perspective, we develop turnkey solutions to create cost savings,
improve efficiency, and better quality control.
              </p>
            </div>
            <div class="w-full lg:w-12/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUSTAINABLE MATERIALS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                The efficient use of sustainable materials can provide too many benefits to list, but too often companies
are faced with the question to do what’s best for the planet or what’s best for their bottom line, and the
benefits are unable to out-weigh the costs. We strive to tip the scales by implementing sustainable
materials and environmentally friendly manufacturing practices all while creating cost-savings.
              </p>
            </div>
            <div class="w-full lg:w-12/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                COMMODITIES AND BULK GOODS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Our focus on optimization and efficiency paired with the relationships our team has built across the
globe have positioned us to be competitive even in the most competitive of markets.
              </p>
            </div>
          </div> -->
      
      </div>

      
        <!-- <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div> -->

        <!-- <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                :src=stock7
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4"> -->
              
              <!-- <div class="md:pr-12">
                <div
                  class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-color3"
                >
                  <i class="fas fa-rocket text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">About us</h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  An efficient and cost-effective supply chain enables a business to thrive by freeing up time and
resources for future growth. This is our goal for our clients. We are a team of industry experts with
decades of experience and the skills to address supply chain challenges of any size and provide elegant
solutions.
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Over a hundred products
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Direct access to our services
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Premium branded products</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div> -->
            <!-- </div>
          </div>
        </div> -->
      </section>
        
      </div>
        <!-- <section class="pb-0 py-0 relative block bg-white -mt-24 z-20">

        <div class="container mx-auto px-2 lg:pt-24 lg:pb-64 ">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-black">
                Build with our Expertise
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color1 uppercase last:mr-0 mr-1">
                  Air Freight
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color2 uppercase last:mr-0 mr-1">
                  Ocean Freight
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color3 uppercase last:mr-0 mr-1">
                  Trucking
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color4 uppercase last:mr-0 mr-1">
                  Rail
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color1 uppercase last:mr-0 mr-1">
                  Intermodal Services
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color4 uppercase last:mr-0 mr-1">
                  Documentation Management
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color3 uppercase last:mr-0 mr-1">
                  Customs & FDA Clearance
                </span>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-color2 uppercase last:mr-0 mr-1">
                  Distribution
                </span>
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-black">
                LOGISTICS
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Not having goods delivered on-time can have serious consequences and navigating a complex
international trade and global logistics system can be a daunting. Allow our experienced team to ease
the burden. Our logistics team operates with urgency, ownership, and respect for the importance of
every delivery under our watch. We use a process and technology driven approach to ensure your goods
arrive efficiently and on time, every time.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUPPLY CHAIN OPTIMIZATION
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Every supply chain has its challenges and pain points… today this more evident than ever. Our team
flourishes in these challenges. Our expertise and network of global manufacturing partners allow us to
view supply chain challenges through a unique lens constantly adjusting from a global perspective to a
focused detail. Equipped with this perspective, we develop turnkey solutions to create cost savings,
improve efficiency, and better quality control.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                SUSTAINABLE MATERIALS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                The efficient use of sustainable materials can provide too many benefits to list, but too often companies
are faced with the question to do what’s best for the planet or what’s best for their bottom line, and the
benefits are unable to out-weigh the costs. We strive to tip the scales by implementing sustainable
materials and environmentally friendly manufacturing practices all while creating cost-savings.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-cog text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-black">
                COMMODITIES AND BULK GOODS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Our focus on optimization and efficiency paired with the relationships our team has built across the
globe have positioned us to be competitive even in the most competitive of markets.
              </p>
            </div>
          </div>
        </div>
      </section> -->

    <!-- <section class="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-100 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div class="container mx-auto">
        <div class="flex flex-wrap items-center">
          <div
            class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32"
          >
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-color2"
            >
              <img
                alt="..."
                src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
                class="w-full align-middle rounded-t-lg"
              />
              <blockquote class="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    class="text-color5 fill-current"
                  ></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">
                  Title1
                </h4>
                <p class="text-md font-light mt-2 text-white">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima voluptatibus magnam possimus accusantium non consequatur quae, tempora veritatis assumenda quod aspernatur, delectus fuga aliquam reprehenderit libero vero ducimus soluta tenetur.
                </p>
              </blockquote>
            </div>
          </div>

          <div class="w-full md:w-6/12 px-4">
            <div class="flex flex-wrap">
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-sitemap"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Comp1 Title
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima voluptatibus magnam possimus accusantium non consequatur quae,
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Comp1 Title
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima voluptatibus magnam possimus accusantium non consequatur quae,
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-6/12 px-4">
                <div class="relative flex flex-col min-w-0 mt-4">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-newspaper"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">Comp3 Title</h6>
                    <p class="mb-4 text-blueGray-500">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima voluptatibus magnam possimus accusantium non consequatur quae,
                    </p>
                  </div>
                </div>
                <div class="relative flex flex-col min-w-0">
                  <div class="px-4 py-5 flex-auto">
                    <div
                      class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                    >
                      <i class="fas fa-file-alt"></i>
                    </div>
                    <h6 class="text-xl mb-1 font-semibold">
                      Comp4 Title
                    </h6>
                    <p class="mb-4 text-blueGray-500">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima voluptatibus magnam possimus accusantium non consequatur quae,
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mx-auto overflow-hidden pb-20">
        <div class="flex flex-wrap items-center">
          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-sitemap text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Title 2
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto consectetur aut saepe quidem dolor repudiandae non fuga impedit, id reprehenderit suscipit pariatur, voluptatum doloribus aperiam placeat blanditiis ducimus. Deserunt, ullam.
            </p>
            <div class="block pb-6">
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Air Freight
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Ocean Freight
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Trucking
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Rail
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Intermodal Services
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Documentation Management
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Customs & FDA Clearance
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Distribution
              </span>
            </div>
            <a
              href=""
              target=""
              class="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
            >
              View All
              <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
            </a>
          </div>

          <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
            <div
              class="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0"
            >
              <img
                alt="..."
                :src="stock6"
                class="w-full align-middle rounded absolute shadow-lg max-w-100-px left-145-px -top-29-px z-3"
              />
              <img
                alt="..."
                :src="stock3"
                class="w-full align-middle rounded-lg absolute shadow-lg max-w-210-px left-260-px -top-160-px"
              />
              <img
                alt="..."
                :src="stock4"
                class="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px left-40-px -top-225-px z-2"
              />
              <img
                alt="..."
                :src="stock5"
                class="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
              />
              <img
                alt="..."
                :src="stock2"
                class="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px"
              />
              <img
                alt="..."
                :src="stock7"
                class="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-center pt-32">
          <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
            <div class="justify-center flex flex-wrap relative">
              <div class="my-4 w-full lg:w-6/12 px-4">
                <a
                  href=""
                  target="_blank"
                >
                  <div class="bg-color4 shadow-lg rounded-lg text-center p-8">
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src=""
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Comp1
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  target="_blank"
                >
                  <div
                    class="bg-color3 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src=""
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Comp2
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  target="_blank"
                >
                  <div
                    class="bg-color2 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src=""
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Comp3
                    </p>
                  </div>
                </a>
              </div>
              <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                <a
                  href=""
                  target="_blank"
                >
                  <div
                    class="bg-color4 shadow-lg rounded-lg text-center p-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src=""
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Comp4
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  target="_blank"
                >
                  <div
                    class="bg-color3 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src=""
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Comp5
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  target="_blank"
                >
                  <div
                    class="bg-color2 shadow-lg rounded-lg text-center p-8 mt-8"
                  >
                    <img
                      alt="..."
                      class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                      src=""
                    />
                    <p class="text-lg text-white mt-4 font-semibold">
                      Comp6
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
            <div
              class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
            >
              <i class="fas fa-drafting-compass text-xl"></i>
            </div>
            <h3 class="text-3xl mb-2 font-semibold leading-normal">
              Title 3
            </h3>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ducimus molestiae id aliquam sapiente optio quis molestias nobis minima error esse pariatur laborum totam qui nemo itaque, voluptates dolorum. Eaque, rerum.
            </p>
            <p
              class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
            >
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius ipsum amet suscipit
            </p>
            <div class="block pb-6">
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Air Freight
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Ocean Freight
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Trucking
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Rail
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Intermodal Services
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Documentation Management
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Customs & FDA Clearance
              </span>
              <span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2"
              >
                Distribution
              </span>
            </div>
            <a
              href=""
              target=""
              class="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
            >
              View all
              <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="container mx-auto px-4 pb-32 pt-48">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-5/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-file-alt text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">
                Title 4
              </h3>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum vel iure dolor libero sunt consequatur numquam quibusdam ea, obcaecati repudiandae alias, dolores quaerat omnis necessitatibus cupiditate nisi magnam vitae nulla.
              </p>
              <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-color1">
                          Over a hundred products
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-color4">
                          Direct access to our services
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Premium branded products</h4>
                      </div>
                    </div>
                  </li>
                </ul>
            </div>
          </div>

          <div class="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
            <img
              alt="..."
              :src="stock3"
              class="max-w-full rounded-lg shadow-xl"
              style="
                transform: scale(1) perspective(1040px) rotateY(-11deg)
                  rotateX(2deg) rotate(2deg);
              "
            />
          </div>
        </div>
      </div>

      <div class="justify-center text-center flex flex-wrap mt-24">
        <div class="w-full md:w-6/12 px-12 md:px-4">
          <h2 class="font-semibold text-4xl">Tag Line here</h2>
          <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis ea laborum veniam eligendi, dolore aliquid culpa expedita ab, similique reprehenderit rerum adipisci provident, fuga numquam quod nostrum ducimus quam nulla.
          </p>
        </div>
      </div>
    </section> -->

    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/FooterAdmin.vue";

import team2 from "@/assets/img/team-2-800x800.jpg";
import logo_black from "@/assets/img/vmd_logo_gear_color.png";

import stock1 from "@/assets/img/stock1.jpg";
import stock2 from "@/assets/img/stock2.jpeg";
import stock3 from "@/assets/img/stock3.jpeg";
import stock4 from "@/assets/img/stock4.webp";
import stock5 from "@/assets/img/stock5.jpeg";
import stock6 from "@/assets/img/stock6.jpeg";
import stock7 from "@/assets/img/stock7.jpeg";
import stock8 from "@/assets/img/stock8.webp";
import stock9 from "@/assets/img/stock9.jpeg";
import stock10 from "@/assets/img/stock10.jpeg";
import single_gear from "@/assets/img/single_gear_white_vmd.svg";
import single_gear_black from "@/assets/img/single_gear_black_vmd.svg";
import single_gear_light from "@/assets/img/single_gear_bg_light_color.svg";
import single_gear_dark from "@/assets/img/single_gear_bg_color.svg";


import stock_large_logisitics from "@/assets/img/stock_large_logisitics.jpeg"; //stock_large_logisitics.3503aab2.jpeg
import stock_big_ship from "@/assets/img/stock_big_ship.jpeg"; //stock_big_ship.20f05f2a.jpeg
import stock_floating_gears from "@/assets/img/stock_floating_gears.jpeg"; //stock_floating_gears.e9687c64.jpeg
import stock_light_bulbs from "@/assets/img/stock_light_bulbs.jpeg"; //stock_light_bulbs.86cb9922.jpeg

import axios from "axios";

export default {
  data() {
    return {
      single_gear_light,
      single_gear_dark,
      single_gear,
      single_gear_black,
      team2,
      logo_black,
      stock_large_logisitics,
      stock_big_ship,
      stock_floating_gears,
      stock_light_bulbs,
      stock1,
      stock2,
      stock3,
      stock4,
      stock5,
      stock6,
      stock7,
      stock8,
      stock9,
      stock10,
      openTab: 1,
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
  methods:{
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },
    send_form_info(){
      const form_info = { 
        full_name: this.full_name_form,
        email: this.email_form,
        message: this.message_form };
      axios.post("https://us-central1-vmdglobalsourcing.cloudfunctions.net/sendMailOverHTTP", form_info)
        .then(response => this.post_response = response)
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
        this.full_name_form = '';
        this.email_form = '';
        this.message_form = '';
        this.$swal({
            icon: 'success',
            title: 'Form has been sent!',
            html: 'Please give us 24 hours to respond to your message!'
            });
    },
  },
};
</script>

<style scoped>

.full_page{
  min-height: 100vh;
}

.base-height{
    min-height: 20vh;
}
</style>
