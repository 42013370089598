<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in with
              </h6>
            </div>
            <div class="btn-wrapper text-center">
              <!-- <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="github" />
                Github
              </button> -->
              <button
                v-on:click="googleLogin()"
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="..." class="w-5 mr-1" :src="google" />
                Google
              </button>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Or sign in with credentials</small>
              <br>
              <small class="red-0" v-if="login_error">{{ login_error }}</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  v-model="authInput.txtEmail"
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  v-model="authInput.txtPassword"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  v-on:click="Login()"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Sign In
                </button>
              </div>
              <div class="text-center mt-6">
                <!-- <button
                  v-on:click="LogOut()"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Logout
                </button> -->
              </div>
            </form>
          </div>
           <!-- <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--4-col"></div>
            <div class="mdl-cell mdl-cell--4-col">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <input class="mdl-textfield__input" type="email" id="txtEmail" v-model="authInput.txtEmail">
                <label class="mdl-textfield__label" for="txtEmail">Email</label>
                </div>
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <input class="mdl-textfield__input" type="Password" id="txtPassword" v-model="authInput.txtPassword">
                <label class="mdl-textfield__label" for="txtPassword">Password</label>
                </div>
                <button id="btnLogin" v-on:click="Login()" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--primary">
            Log in
            </button>
            <button id="btnSignUp" v-on:click="SignUp()"  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
            Sign Up
            </button>
            <button id="btnLogout" v-on:click="LogOut()" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button" style="display:none">
            Log out
            </button>
            <button v-on:click="googleLogin()" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button">
            Google in
            </button>
            </div>
           </div> -->
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Create new account</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import github from "@/assets/img/github.svg";
import google from "@/assets/img/google.svg";
import firebase from 'firebase'

var provider = new firebase.auth.GoogleAuthProvider();

const app = firebase.app();
let db = app.database()
let users = db.ref('users')

// db.ref('users/' + id).get()
//   .then(function(snapshot) {
//     if(!snapshot.exists()) {
//       db.ref('users/' + id).set({
//         isFree: true,
//         numQueries: 5,
//       });

// this.$rtdbBind('user', users.child(this.id)).then(user => {
//   // user will be an object if this.user was set to anything but an array
//   // and it will point to the same property declared in data:
//   // this.user === user
// })

export default {
  firebase: {
            users: users.limitToLast(5)
        },
  data() {
    return {
      github,
      google,
      displayname: '',
      // current_user: null,
      google_login_result: {},
      gpt_response: null,
      gpt_input: "",
      photoURL: '',
      authInput: {
          txtEmail: '',
          txtPassword: ''
      },
      newGreeting: {
          lang: '',
          text: ''
      },
      login_error: null,
    };
  },
        // watcher
        beforeMount() {
            //While firebase is initializing `firebase.auth().currentUser` will be null
            let currentUser = firebase.auth().currentUser;
            //console.log(currentUser)

            //Check currentUser in case you previously initialize firebase somewhere else
            if (currentUser) {
            //if currentUser is ready we just finish here
            //console.log(currentUser)
            this.displayname = currentUser.displayname;
            this.photoURL = currentUser.photoURL;
            // this.authorization = currentUser.credential;
            // console.log
            } else {
            // if currentUser isn't ready we need to listen for changes
            // onAuthStateChanged takes a functions as callback and also return a function
            // to stop listening for changes 
            let authListenerUnsuscribe = firebase.auth().onAuthStateChanged(user => {
                //onAuthStateChanged can pass null when logout 
                if (user) {
                //console.log(user)
                //console.log(user.email)
                this.photoURL = user.photoURL;
                this.displayname = user.email;
                authListenerUnsuscribe(); //Stop listening for changes
                }
            });
            }
        },
        // watch: {
        //     current_user(newValue) {
        //         console.log("User exists" + newValue);
        //         this.$router.push('admin');
        //     }
        // },
        // computed: {
        //     current_user: function () {
        //         // var current_user = await firebase.auth().currentUser
        //         // if(current_user != null){this.$router.push('admin');}
        //         return firebase.auth().currentUser
        //     }
        // },
        computed: {
            current_user() {
                 return firebase.auth().currentUser
            }
        },

        watch: {
              current_user(newValue) {
                  if(newValue != null){this.$router.push('admin');}
                  else{this.$router.push('admin');}
              }
          },
        methods: {
            Login: function() {
                //console.log(event)
                const email = this.authInput.txtEmail;
                const pass = this.authInput.txtPassword;
                const auth = firebase.auth();
                // const recaptcha = firebase.auth().RecaptchaVerifier
                const promise = auth.signInWithEmailAndPassword(email, pass);
                this.authInput.txtEmail = '';
                this.authInput.txtPassword = '';
                var that = this
                //this.push_router_to_console()
                promise.catch(event =>{
                  console.log(event)
                  this.login_error = event.message
                }).then(() => {
                    if(this.login_error == null){
                    //console.log("User logged in")
                    that.push_router_to_console()
                  }
                });
            },
            // SignUp: function(event) {
            //     console.log(event);
            //     const email = this.authInput.txtEmail;
            //     const pass = this.authInput.txtPassword;
            //     const auth = firebase.auth();
            //     const promise = auth.createUserWithEmailAndPassword(email, pass);
            //     this.authInput.txtEmail = '';
            //     this.authInput.txtPassword = '';
            //     promise.catch(event => console.log(event.message));

            // },
            push_router_to_console: function(){
                this.$router.push('/dashboard')
            },
            // set_user_rtdb: function(result){
            //   users.ref('users/' + result.user.uid).set(result.user).catch(error => {
            //           console.log("rtdb erroring")
            //           console.log(error)
            //       });
            // },
            // check_set_user(uid){
            //   console.log("Checking this UID" , uid)
            //   // db.ref('users/' + uid).set({
            //   //         customer_id: "",
            //   //         permission: 1
            //   //       })
            //   db.ref('users/' + uid).get()
            //     .then(function(snapshot) {
            //       console.log("Snapshot")
            //       console.log(snapshot)
            //       if(!snapshot.exists()) {
            //         console.log('hits')
            //         db.ref('users/' + uid).set({
            //           customer_id: "",
            //           permission: 1
            //         })
            //       }else{
            //         console.log("awlays here")
            //       }
            //   })
            // },
            googleLogin: function() {
                var that = this
                firebase.auth().signInWithPopup(provider).then(function(result) {
                  //console.log("This is the user that is returned on login");
                  console.log(result.user.uid +  ': logged in');
                  //that.check_set_user(result.user.uid);
                  // db.ref('users/' + result.user.uid).set({
                  //   customer_id: "",
                  // });
                  //console.log(firebase.auth().currentUser.uid);
                  //that.set_user_rtdb(result);
                  //console.log(result.credential.idToken)
                  //this.google_login_result = result
                  //console.log("This is where the router should push to console");
              
                  that.push_router_to_console()
                }).catch(function() {
                    // console.log("An error with google sign in has occured: " + error);
                    that.push_router_to_console()
                });
            },
            // LogOut: function() {
            //     firebase.auth().signOut();
            //     this.displayname = ""
            //     this.photoURL = ""
            // },

        }
      
};

// firebase.auth().onAuthStateChanged((user) => {
//       if (user) {
//         this.user = user;
//         this.$router.push("/admin/settings");
//       } else {
//         this.user = null;
//       }
// })

// firebase.auth().onAuthStateChanged(firebaseUser => {
//         // console.log("This is the displayname: " + firebaseUser.displayname);
//         //console.log(firebase.auth().currentUser.displayName())
//         //this.displayname = firebase.auth().currentUser.displayName
//         if (firebaseUser) {
//             // firebaseUser.sendEmailVerification().then(function() {
//             //     console.log('send Verification');
//             // }, function(error) {
//             //     console.log('not send Verification');
//             // });
//             if (firebaseUser.emailVerified == true) {
//                 //console.log('login success');
//                 //document.getElementById('btnLogout').style.display = '';
//                 //this.store_firebase_user(firebaseUser)
//             } else {
//                 //document.getElementById('btnLogout').style.display = 'none';
//             }

//         } else {
//             //console.log('not loggend in');
//             //document.getElementById('btnLogout').style.display = 'none';
//         }
//     })
</script>
