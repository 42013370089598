<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-1">
      <CardSettings />
    </div>
    <!-- <div class="w-full lg:w-4/12 px-4">
      <CardProfile />
    </div> -->
  </div>
</template>
<script>
import CardSettings from "@/components/Cards/CardSettings.vue";
// import CardProfile from "@/components/Cards/CardProfile.vue";
import firebase from "firebase";

export default {
  components: {
    CardSettings,
    // CardProfile,
  },
  data() {
    return {
      user: null
    };
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
        this.$router.push("/");
      }
    })
  },
};
</script>
