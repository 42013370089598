// import { createApp } from "vue";
// import { createWebHistory, createRouter } from "vue-router";

//vuefire
import VueFire from 'vuefire'

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

//import App from "@/App.vue";
import Vue from 'vue'
import App from './App'
import router from './router'
// import VueFire from 'VueFire'

// import { Plugin } from 'vue-responsive-video-background-player'

// Vue.use(Plugin);

// import "./index.css"

//swal popups
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#64748b',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2 , options);

//Sliding Pannels
import VueSlideoutPanel from 'vue2-slideout-panel';
Vue.use(VueSlideoutPanel);

//leaflet

import { LMap, LTileLayer, LMarker, LGeoJson, LIcon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
// import vuetify from './plugins/vuetify'

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-icon' , LIcon);

Vue.use(VueFire)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App }
})


// layouts

// import Admin from "@/layouts/Admin.vue";
// import Auth from "@/layouts/Auth.vue";

// // views for Admin layout

// import Dashboard from "@/views/admin/Dashboard.vue";
// import Settings from "@/views/admin/Settings.vue";
// import Tables from "@/views/admin/Tables.vue";
// import Maps from "@/views/admin/Maps.vue";

// // views for Auth layout

// import Login from "@/views/auth/Login.vue";
// import Register from "@/views/auth/Register.vue";

// // views without layouts

// import Landing from "@/views/Landing.vue";
// import Profile from "@/views/Profile.vue";
// import Index from "@/views/Index.vue";
// import windex from "@/views/windex.vue";
// routes

// const routes = [
//   {
//     path: "/admin",
//     redirect: "/admin/dashboard",
//     component: Admin,
//     children: [
//       {
//         path: "/admin/dashboard",
//         component: Dashboard,
//       },
//       {
//         path: "/admin/settings",
//         component: Settings,
//       },
//       {
//         path: "/admin/tables",
//         component: Tables,
//       },
//       {
//         path: "/admin/maps",
//         component: Maps,
//       },
//     ],
//   },
//   {
//     path: "/auth",
//     redirect: "/auth/login",
//     component: Auth,
//     children: [
//       {
//         path: "/auth/login",
//         component: Login,
//       },
//       {
//         path: "/auth/register",
//         component: Register,
//       },
//     ],
//   },
//   {
//     path: "/landing",
//     component: Landing,
//   },
//   {
//     path: "/profile",
//     component: Profile,
//   },
//   {
//     path: "/windex",
//     component: windex,
//   },
//   {
//     path: "/",
//     component: Index,
//   },
//   { path: "/:pathMatch(.*)*", redirect: "/" },
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

// createApp(App).use(router , VueFire).mount("#app");
