<template>
  <div class="main">
      <IndexNavbar />
    <div class="flex flex-wrap top-space top_pad bg-color2">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart />
      </div>
    </div>
    <!-- <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-page-visits />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-social-traffic />
      </div>
    </div> -->
    <!-- <UnderMap /> -->
  </div>
</template>
<script>
//import UnderMap from "@/components/UnderMap.vue";
import CardLineChart from "@/components/Cards/left_table1.vue";
import CardBarChart from "@/components/Cards/right_table1.vue";
// import CardPageVisits from "@/components/Cards/CardPageVisits.vue";
// import CardSocialTraffic from "@/components/Cards/CardSocialTraffic.vue";

import IndexNavbar from "@/components/Navbars/IndexNavbar_blank.vue";

export default {
  name: "dashboard-page",
  components: {
    //UnderMap,
    IndexNavbar,
    CardLineChart,
    CardBarChart,
    // CardPageVisits,
    // CardSocialTraffic,
  },
};
</script>
<style>
html
{
    /* background: #1e1a20; */
    background-image: none !important;
}
.main{
    margin-top: 1em;
    background-color: transparent;
}
.top_space{
    margin-top: 5em;
}

.top_pad{
  padding-top: 5em;
}

.map_bg{
    position: fixed !important; 
    top: 0 !important;
    left: 0 !important;
    outline: none !important;
    z-index: -100 !important;
}
</style>