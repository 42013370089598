<template>
  <div>
    <navbar />
    <main class="profile-page">
        <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center full-page"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover "
          style="
            background-image: url('/img/stock_floating_gears.e9687c64.jpeg');
          "
        >
          <!-- <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span> -->
        </div>
        <!-- <section class="mt-20 relative block bg-white rounded "> -->
      <section class="relative py-40 mt-20 relative block bg-white rounded shadow-lg">
        <!-- <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div> -->

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <!-- <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                :src=stock7
              />
            </div> -->
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-color3"
                >
                  <!-- <i class="fas fa-cog text-xl"></i> -->
                  <i class="fas"><img :src="single_gear" class="" alt=""/></i>
                  
                </div>
                <h3 class="text-3xl font-semibold">About us</h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  An efficient and cost-effective supply chain enables a business to thrive by freeing up time and
resources for future growth. This is our goal for our clients. We are a team of industry experts with
decades of experience and the skills to address supply chain challenges of any size and provide elegant
solutions.
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-cog"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Over a hundred products
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-cog"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Direct access to our services
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-cog"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Premium branded products</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>

      <!-- <section class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">Our Team</h2>
              <p class="text-lg leading-relaxed m-4 text-blueGray-500">
                Meet our dedicated team of founders, who have a combined experience of over a decade in the Sourcing / Supply chain industry.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="https://via.placeholder.com/150"
                  class="shadow-lg rounded-full mx-auto max-w-120-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Kyle Townson</h5>
                  <p
                    class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                  >
                    Title
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-linkedin"></i>
                    </button>
                    <button
                      class="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </button>
                    <button
                      class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-dribbble"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="https://via.placeholder.com/150"
                  class="shadow-lg rounded-full mx-auto max-w-120-px"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Dan Fritz</h5>
                  <p
                    class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                  >
                    Title
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-linkedin"></i>
                    </button>
                    <button
                      class="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </button>
                    <button
                      class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-dribbble"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section> -->

    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/FooterAdmin.vue";

import backdrop from "@/assets/img/Main_backdrop.jpg";
import logo_black from "@/assets/img/vmd_logo_gear_color.png";
import stock1 from "@/assets/img/stock1.jpg";
import stock2 from "@/assets/img/stock2.jpeg";
import stock3 from "@/assets/img/stock3.jpeg";
import stock4 from "@/assets/img/stock4.webp";
import stock5 from "@/assets/img/stock5.jpeg";
import stock6 from "@/assets/img/stock6.jpeg";
import stock7 from "@/assets/img/stock7.jpeg";
import stock8 from "@/assets/img/stock8.webp";
import stock9 from "@/assets/img/stock9.jpeg";
import stock10 from "@/assets/img/stock10.jpeg";
import single_gear from "@/assets/img/single_gear_white_vmd.svg";
import single_gear_black from "@/assets/img/single_gear_black_vmd.svg";
import single_gear_light from "@/assets/img/single_gear_bg_light_color.svg";
import single_gear_dark from "@/assets/img/single_gear_bg_color.svg";


import axios from "axios";

export default {
  data() {
    return {
      single_gear,
      single_gear_black,
      single_gear_light,
      single_gear_dark,
      logo_black,
      backdrop,
      stock1,
      stock2,
      stock3,
      stock4,
      stock5,
      stock6,
      stock7,
      stock8,
      stock9,
      stock10,
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
  methods:{
    send_form_info(){
      const form_info = { 
        full_name: this.full_name_form,
        email: this.email_form,
        message: this.message_form };
      axios.post("https://us-central1-vmdglobalsourcing.cloudfunctions.net/sendMailOverHTTP", form_info)
        .then(response => this.post_response = response)
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
        this.full_name_form = '';
        this.email_form = '';
        this.message_form = '';
        this.$swal({
            icon: 'success',
            title: 'Form has been sent!',
            html: 'Please give us 24 hours to respond to your message!'
            });
    },
  },
};
</script>
<style scoped>
.full_page{
  min-height: 100vh;
}
</style>
