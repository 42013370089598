<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            Contact Us
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold text-center">
            Need Additional Invetory?
          </h2>
          <div class="py-6 px-3 mt-32 sm:mt-0 items-center text-center">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">
                    Item Request
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Complete this form with said item request and we will responds within 24 hours.
                  </p>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Requester : {{ currentUser.email }}
                  </p>
                  <!-- <div class="relative w-full mb-3 mt-8">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Full Name
                    </label>
                    <input
                      v-model="full_name_form"
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Full Name"
                    />
                  </div> -->

                  <!-- <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      v-model="email_form"
                      type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div> -->

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      What item are you looking for?
                    </label>
                    <textarea
                      v-model="message_form"
                      rows="4"
                      cols="80"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Type a message..."
                    />
                  </div>
                  <div class="text-center mt-6">
                    <button
                      @click="send_form_info()"
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>      
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="p-4 flex-auto">
      <div class="relative h-350-px">
        <canvas id="bar-chart"></canvas>
      </div>
    </div> -->
  </div>
</template>
<script>
//import Chart from "chart.js";
import axios from "axios";
import firebase from 'firebase'

export default {
  name: 'right_table1',
  data() {
    return {
      full_name_form: '',
      email_form: '',
      message_form: '',
      post_response: '',
      errorMessage: '',
      currentUser: {},
    };
  },
  methods:{
    send_form_info(){
      //console.log(this.currentUser)
      const form_info = { 
        full_name: "Item Request for client: " + this.current_user.displayName,
        email: this.current_user.email,
        message: this.message_form };
      axios.post("https://us-central1-vmdglobalsourcing.cloudfunctions.net/sendMailOverHTTP", form_info)
        .then(response => this.post_response = response)
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
        this.full_name_form = '';
        this.email_form = '';
        this.message_form = '';
        this.$swal({
            icon: 'success',
            title: 'Form has been sent!',
            html: 'Please give us 24 hours to respond to your message!'
            });
    },
  },
  computed: {
            current_user: function () {
                return firebase.auth().currentUser
            }
  },
  beforeMount: function () {
    this.currentUser = firebase.auth().currentUser;
  },
  // mounted: function () {
  //   this.$nextTick(function () {
  //     let config = {
  //       type: "bar",
  //       data: {
  //         labels: [
  //           "January",
  //           "February",
  //           "March",
  //           "April",
  //           "May",
  //           "June",
  //           "July",
  //         ],
  //         datasets: [
  //           {
  //             label: new Date().getFullYear(),
  //             backgroundColor: "#000000",
  //             borderColor: "#000000",
  //             data: [30, 78, 56, 34, 100, 45, 13],
  //             fill: false,
  //             barThickness: 8,
  //           },
  //           {
  //             label: new Date().getFullYear() - 1,
  //             fill: false,
  //             backgroundColor: "#808080",
  //             borderColor: "#808080",
  //             data: [27, 68, 86, 74, 10, 4, 87],
  //             barThickness: 8,
  //           },
  //         ],
  //       },
  //       options: {
  //         maintainAspectRatio: false,
  //         responsive: true,
  //         title: {
  //           display: false,
  //           text: "Orders Chart",
  //         },
  //         tooltips: {
  //           mode: "index",
  //           intersect: false,
  //         },
  //         hover: {
  //           mode: "nearest",
  //           intersect: true,
  //         },
  //         legend: {
  //           labels: {
  //             fontColor: "rgba(0,0,0,.4)",
  //           },
  //           align: "end",
  //           position: "bottom",
  //         },
  //         scales: {
  //           xAxes: [
  //             {
  //               display: false,
  //               scaleLabel: {
  //                 display: true,
  //                 labelString: "Month",
  //               },
  //               gridLines: {
  //                 borderDash: [2],
  //                 borderDashOffset: [2],
  //                 color: "rgba(33, 37, 41, 0.3)",
  //                 zeroLineColor: "rgba(33, 37, 41, 0.3)",
  //                 zeroLineBorderDash: [2],
  //                 zeroLineBorderDashOffset: [2],
  //               },
  //             },
  //           ],
  //           yAxes: [
  //             {
  //               display: true,
  //               scaleLabel: {
  //                 display: false,
  //                 labelString: "Value",
  //               },
  //               gridLines: {
  //                 borderDash: [2],
  //                 drawBorder: false,
  //                 borderDashOffset: [2],
  //                 color: "rgba(33, 37, 41, 0.2)",
  //                 zeroLineColor: "rgba(33, 37, 41, 0.15)",
  //                 zeroLineBorderDash: [2],
  //                 zeroLineBorderDashOffset: [2],
  //               },
  //             },
  //           ],
  //         },
  //       },
  //     };
  //     let ctx = document.getElementById("bar-chart").getContext("2d");
  //     window.myBar = new Chart(ctx, config);
  //   });
  // },
};
</script>
