<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            Signed in as: {{ current_user.displayName }}
          </h6>
          <h2 class="text-color1 text-xl font-semibold">
            Invetory Restocking
          </h2>
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            Items listed for purchase
          </h6>
          </div>
          <stripe-checkout
          ref="checkoutRef"
          mode="payment"
          :pk="publishableKey"
          :line-items="lineItems"
          :success-url="successURL"
          :cancel-url="cancelURL"
          @loading="v => loading = v"
        />
        <!-- <button @click="submit" class="text-black bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
        Payment Portal Test Item
        </button> -->
        
      </div>
    </div>
    
    <div class="relative flex flex-wrap justify-center py-2" v-if="this.loading_items">
          
          <SelfBuildingSquareSpinner
            :animation-duration="2000"
            :size="45"
            color="#79b4d8"
          />
          <br>
          <p class="py-5 mx-2 text-color1">Loading Items for {{ current_user.displayName }}.....</p>
      </div>
    <div v-else class="main_wrapper">
    
    <div class="p-4 flex-auto" v-if="show_cart">
      <!-- Json Display data -->
      <div>
        <h2 class="text-blueGray-700 text-xl font-semibold">
            Current Cart
          </h2>
          <!-- <card-table/> -->
          <!-- { "itemid": "IY001", "itemdiscription": "Smoothie Straw", "totalprice": 114.94, "priceperunit": 0.05, "numberofunits": 2500, "purchase_ammount": 1, "added_to_cart": true }  -->
          <table class="table-auto overflow-x-auto w-full text-xs text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="col_1"><i class="fas fa-photo"></i></th>
              <th scope="col" class="col_2">Quantity</th>
              <th scope="col" class="col_3">Item ID</th>
              <th scope="col" class="col_4">Item Description</th>
              <!-- <th scope="col" class="col_5">Price Per Unit</th> -->
              <th scope="col" class="col_6">Number of Units</th>
              <th scope="col" class="col_7">Price</th>
              <th scope="col" class="col_8">Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item , index) in this.item_list_added" :key="index">
            <!-- <div  v-if="index != (this.item_list.length - 1)"> only once s</div> -->
            <td v-if="item.added_to_cart" class="col_1">
                  <img style="width:100px; height:100px;" :src="require(`@/assets/img/pack_images/${item.itemid}.webp`)" alt="..." class="shadow-lg rounded max-w-full h-auto align-middle border-none" />
            </td>
            <td v-if="item.added_to_cart" class="col_2">
               <input v-model="item.purchase_ammount" min="0" type="number" placeholder="Order Ammount" class="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
            </td>
            <td v-if="item.added_to_cart" class="col_3">
                {{ item.itemid }}
            </td>
            <td v-if="item.added_to_cart" class="col_4">
                {{ item.itemdiscription }}
            </td>
            <!-- <td v-if="item.added_to_cart" class="col_5">
                {{ item.priceperunit }}
            </td> -->
            <td v-if="item.added_to_cart" class="col_6">
                {{ item.numberofunits }}
            </td>
            <td v-if="item.added_to_cart" class="col_7">
                {{ item.totalprice }}
            </td>
            <td v-if="item.added_to_cart" class="col_8">
                {{ (item.totalprice * item.purchase_ammount).toFixed(2) }}
            </td>
            </tr>
            <tr>
              <td class="col_1"></td>
              <td class="col_2"></td>
              <td class="col_3"></td>
              <td class="col_4"></td>
              <!-- <td class="col_5"></td> -->
              <td class="col_6"></td>
              <td class="col_7">Total_Price:</td>
              <td class="col_8 text-3xl font-normal leading-normal mt-0 mb-2 text-blueGray-800">
                  {{ this.total_cart_value }}
              </td>
            </tr>
            <tr>
              <td class="col_1"></td>
              <td class="col_2"></td>
              <td class="col_3"></td>
              <td class="col_4"></td>
              <!-- <td class="col_5"></td> -->
              <td class="col_6"></td>
              <td class="col_7"></td>
              <td class="col_8">
                  <button @click="submit" class="text-black bg-transparent border border-solid border-black hover:bg-black hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fas fa-shopping-cart"></i> Place Order
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <hr>
      <br> -->
    </div>
    <div class="p-4 flex-auto">

      <div class="flex flex-wrap">
          <div class="w-full px-3 flex" v-for="(item , index) in this.item_list" :key="index"> <!-- v-for="(vuln , index) in this.found_vulns" :key="index" -->
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
              <div class="flex flex-wrap items-center">
                <div class="relative w-full max-w-full flex-grow flex-1">
                  <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                    {{ item.itemid }}
                  </h6>
                  <h2 class="text-color1 text-xl font-semibold">
                    {{ item.itemdiscription }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="px-4 py-5 flex-auto">
              <div class="flex flex-wrap">
              <div class="w-full px-4 flex-1 md:w-auto md:flex-none">
                <div class="item_image px-0">
                  <img :src="require(`@/assets/img/pack_images/${item.itemid}.webp`)" alt="..." class="shadow-lg rounded max-w-full h-auto align-middle border-none" />
                </div>
              </div>
              <div class="w-full px-4 flex-1 md:w-2/12 md:flex-none">
                <!-- <span class="text-sm block p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
                  Price Per Unit : {{item.priceperunit}}
                </span> -->
                <span class="text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
                  Total Price : {{item.totalprice}}
                </span>
                <span class="text-sm block my-4 p-3 text-blueGray-700 rounded border border-solid border-blueGray-100">
                  Total Number of Unit(s) : {{item.numberofunits}}
                </span>
              </div>
              <div v-if="!item.added_to_cart" class="w-full px-4 flex-1 md:w-2/12 md:flex-none">
                <div class="flex float-right">
                  <button @click="add_purchase_ammount(index)" class="text-black bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fas fa-plus"></i>
                  </button>
                  <button @click="minus_purchase_ammount(index)" class="text-black bg-transparent border border-solid border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fas fa-minus"></i>
                  </button>
                  <div class="mb-3 pt-0">
                    <input v-model="item.purchase_ammount" min="0" type="number" placeholder="Order Ammount" class="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"/>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div class="px-4 py-5 flex-auto">
              <div class="flex flex-wrap">
              <div class="w-full px-4 flex-1 md:w-auto md:flex-none">
                
              </div>
              <div class="w-full px-4 flex-1 md:w-2/12 md:flex-none">
                
              </div>
              <div v-if="!item.added_to_cart" class="w-full px-4 flex-1 md:w-2/12 md:flex-none">
                <div class="flex float-right">
                  <button @click="add_item_to_cart(index)" class="text-black bg-transparent border border-solid border-black hover:bg-black hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fas fa-shopping-cart"></i> Add to Cart
                  </button>
                  <button class="bg-color4 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                  Instant Reorder
                  </button>
                </div>
                </div>
                <div v-else>
                <button @click="remove_from_cart(index)" class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                  Remove from Cart
                  </button>
                  <button @click="scrollToTop()" class="text-color1 bg-transparent border border-solid border-color1 hover:bg-color1 hover:text-white active:bg-color1 font-bold uppercase text-s px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    <i class="fas fa-shopping-cart"></i>
                  </button>
              </div>
              </div>
            </div>
          </div> 
          </div>
      </div>

    </div>
    </div>
  </div>
</template>
<script>
// import jsonPretty from '../ui_element/json_pretty.vue'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
//import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';

import { SelfBuildingSquareSpinner} from 'epic-spinners'

//import CardTable from "@/components/Cards/CardTable.vue";

//const axios = require('axios').default;

import firebase from 'firebase'

let database = firebase.database()



export default {
  components: {
    //VueJsonPretty,
    //FingerprintSpinner,
    //CardTable,
    SelfBuildingSquareSpinner,
    StripeCheckout,
    // jsonPretty,
  },
  name: 'left_table1',
  data: function () {
    //this.publishableKey = 'pk_test_51LUjFyIuQeenzKgc3o3CyfIlxDoCQO8O5j5XhBxmweomSxZt3uBfw0bXvj9IIj2AQHHyIqMM64t6HuR7ZTyEwr2X00epqepFUd';
    this.publishableKey = 'pk_live_51LT64FEXYvYnaIRd06KThYAu2DSoMIoF3MlqJe422dxi6bvg2Jt90H9LirUtfdnVQJZxrlr59A2KickBpr6AZAk500islTA13m';
    return {
      loading: false,
      loading_items: false,
      show_cart: false,
      input1: 0,
      // lineItems: [
      //   // {
      //   //   //price: 'price_1LUjt5IuQeenzKgcJLqScmhS', // The id of the one-time price you created in your Stripe dashboard
      //   //   price: 'price_1LXRtwEXYvYnaIRdXCV2pYIz',
      //   //   quantity: 1,
      //   // },
      // ],
      successURL: `${window.location.origin}/success`,
      cancelURL: `${window.location.origin}/cancel`,
      item_list: [],
      hostData: {},
      showModal: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,  
      },
      pageTitle: 'Rook.sh - Host Report',
      current_asset:"No scan loaded",
      current_os:"No OS detected",
      currentJson: {
        "results": {
          "service_results": {
            "current": {
              "": {"No scan" : "Loaded"}
            }
          }
        }
      },
      found_vulns: {},
      service_results: '',
      os: '',
      jsonDepth: 1,
      maxDepth: 1,
      rawView: false,
      listView: true,
      pieinputSize: {width:300,height:300},
      lineinputSize: {width:300,height:300},
      showFlag: '',
      showMap: '',
      lat: '',
      long: '',
      loading_scan_data: false,
      total_key: 0,
    }
  },
    computed: {
      lineItems: function () {
        var line_items_out = []
        for (let index = 0; index < this.item_list.length; index++) {
          const item = this.item_list[index];
          if(item.added_to_cart){
            line_items_out.push({
              price: item.stripe_price,
              quantity: parseInt(item.purchase_ammount),
            })
          }
        }
        return line_items_out
      },
        // {
        //   //price: 'price_1LUjt5IuQeenzKgcJLqScmhS', // The id of the one-time price you created in your Stripe dashboard
        //   price: 'price_1LXRtwEXYvYnaIRdXCV2pYIz',
        //   quantity: 1,
        // },
        
      item_list_added: function (){
        var out_list = []
        for (let index = 0; index < this.item_list.length; index++) {
          const item = this.item_list[index];
          if(item.added_to_cart){
            out_list.push(item)
          }
        }
        return out_list
      },
      total_cart_value: function (){
        var total_count = 0;
        for (let index = 0; index < this.item_list.length; index++) {
          const item = this.item_list[index];
          if(item.added_to_cart){
            total_count = total_count + (item.totalprice * item.purchase_ammount);
          }
        }
        // this.item_list.forEach(element , index => {
        //   if(element[index].added_to_cart){
        //     console.log("Item here" + index + total_count);
        //   }
        // });
        return total_count.toFixed(2)
      },
      current_user: function () {
          return firebase.auth().currentUser
      },
      title: function () {
        return this.pageTitle
      },
    hostIn: function () {
      return this.$route.params.metaHost
    },
    pageHeight: function () {
      return this.$refs.refName.clientHeight
    },
    pageWidth: function () {
      return this.$refs.refName.clientWidth
    }
    // infoCondition() {
    //   this.hostData.info[0].meta.host;
    //   return this.hostData.info[0].meta.host !== null && this.hostData.info[0].meta.host !== '';
    // }
  },
  methods: {
    async get_database_items(){
      //console.log("Loading Databse Items");
      var that = this;
      //var customer_id = "";
      //console.log(this.current_user.uid)
      await database.ref('/users/' + this.current_user.uid).once('value').then(function(snapshot){
        //onsole.log(snapshot.val().customer_id)
        that.customer_id = snapshot.val().customer_id;
        console.log('/items/' + snapshot.val().customer_id);
        database.ref('/items/' + snapshot.val().customer_id).once('value')
          .then(function(snapshot2) {
              //console.log(snapshot2.key)
              that.item_list = snapshot2.val();
          })
      })
      // console.log('/items/' + this.customer_id)
      // database.ref('/items/' + this.customer_id).once('value')
      // .then(function(snapshot) {
      //     console.log( snapshot.val() )
      //     that.item_list = snapshot.val();
      // })

    },
    scrollToTop() {
    window.scrollTo(0,0);
    },
    remove_from_cart(index) {
      this.item_list[index].added_to_cart = false
    },
    add_item_to_cart (index) {
      if('purchase_ammount' in this.item_list[index]){
        if(this.item_list[index].purchase_ammount == 0){
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            html: 'Please select an ammount greater than 0 to add to the cart'
            });
        }else{
          this.$set(this.item_list[index] , 'added_to_cart' , true);
          this.show_cart = true;
        }
      }else{
        this.$swal({
        icon: 'error',
        title: 'Oops...',
        html: 'Please select an ammount for the item before adding to cart'
      });
      }
    },
    add_purchase_ammount (index) {
      if('purchase_ammount' in this.item_list[index]){
        this.item_list[index].purchase_ammount++
      }else{
        this.$set(this.item_list[index] , 'purchase_ammount' , 0);
        this.item_list[index].purchase_ammount++
      }
    },
    minus_purchase_ammount (index) {
      if('purchase_ammount' in this.item_list[index]){
        if(this.item_list[index].purchase_ammount != 0){
          this.item_list[index].purchase_ammount-- 
        }
      }else{
        this.$set(this.item_list[index] , 'purchase_ammount' , 0);
      }
    },
    submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
      this.$swal({
            icon: 'success',
            title: 'You are being redirected to secure checkout',
            });
    },

    // toggleModal: function(input_modual){
    //   this.showModal = [false,false,true,false,false,false,false,false,false]
    //   console.log(input_modual)
    //   console.log(this.showModal)
    //   console.log(this.showModal[input_modual])
    //   this.showModal[input_modual] = true;
    //   console.log(this.showModal)
    // },
    toggleLIST_JSON(){
      this.rawView = !this.rawView
      this.listView = !this.listView
    },
    async get_user_token(){
      try{
        var jwt_data = await firebase.auth().currentUser.getToken();
        return jwt_data;
      }
      catch(error){
        this.loading_scan_data = false;
        return {};
        }
    },

  },
    beforeMount() {

      //Fake Timing
      this.loading_items = true
      this.get_database_items();
      this.loading_items = false
      //setTimeout(() => {  this.loading_items = false }, 2000);

      //this.getHostInfo();
      //this.retVulns();
      // console.log("We made it bois")
    },
}
</script>

<style>
.item_image{
  width: 10em;
  height: 10em;
}

.cart_table{
  text-align: center;
  vertical-align: center;
  overflow-x:auto;
  /* margin: 1em;
  margin-right: 1em; */
  width: 100%;
  align-content: center;
}

thead th{
  padding-bottom: 1em;
}

.col_1{
  text-align: center;
}

.col_2{
  text-align: center;
}
.col_3{
  text-align: center;
}

.col_4{
  text-align: center;
}
.col_5{
  text-align: center;
}

.col_6{
  text-align: center;
}

.col_7{
  text-align: right;
}

.col_8{
  text-align: right;
}

@media all and (max-width: 768px) {

    .col_3{
        display:none;
        width:0;
        height:0;
        opacity:0;
        visibility: collapse;       
    } 
    .col_6{
        display:none;
        width:0;
        height:0;
        opacity:0;
        visibility: collapse;       
    }
    .col_5{
        display:none;
        width:0;
        height:0;
        opacity:0;
        visibility: collapse;       
    }
}

@media all and (max-width: 500px) {

    .col_1{
        display:none;
        width:0;
        height:0;
        opacity:0;
        visibility: collapse;       
    } 
}

tbody tr:nth-child(odd) {background: rgb(245, 245, 245)}
tbody tr:nth-child(even) {background: #FFF}

tbody tr:nth-last-child(-n+2) {background: #FFF !important}
tbody tr:last-child {background: #FFF !important}


/* .cart_table tr{
  width: 1em;
}

.cart_table th{
  width: 25%;
}

.cart_table td{
  width: 25%;
}

tr:nth-child(odd) {background: #CCC}
tr:nth-child(even) {background: #FFF}
tr:hover {
    background: red;
}
tr:first-child:hover {
    background: white;
} */

/* thead{
  background: #CCC;
  width: 25%;
} */
</style>